import { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useLoading } from '../../../hooks/useLoading';
import { useToast } from '../../../hooks/useToast';
import { ConsolidateNpcTypeTable } from './consolidateNpcTypeTable';
import { ConsolidationNpcType, ConsolidationNpcTypes, ConsolidationsProvider, Zone, useConsolidationsReducer } from './consolidationReducer';

// https://dmitripavlutin.com/javascript-fetch-async-await/
// https://www.robinwieruch.de/react-hooks-fetch-data/
// https://devtrium.com/posts/async-functions-useeffect
// https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react

function zoneText(zone: Zone) {
  if (zone.zoneNumber > 0) {
    return `${zone.longName} - ${zone.zoneNumber}`;
  }

  return `${zone.longName}`;
}

function ConsolidationList() {
  const { toastInfo, toastError } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token } = useAuth();
  const { state, setConsolidations, setZones, setSelectedZone, toggle, toggleAll } = useConsolidationsReducer();

  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        const response = await fetch('/zones', {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const resultZones = await response.json();
        setZones([{ id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1 }].concat(resultZones));
      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }
    // call the function
    fetchData();
    return () => abortController.abort();
  }, [token]);

  useEffect(() => {
    var selectedZoneId = state.selectedZone.id;
    if(selectedZoneId < 1) {
      setConsolidations({})
      return;
    }

    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      setConsolidations({});
      startLoading();
      try {
        const response = await fetch(`/zones/${selectedZoneId}/npctypes/consolidate`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const consolidateNpcs: ConsolidationNpcTypes = await response.json();
        for (let key of Object.keys(consolidateNpcs)) {
          consolidateNpcs[key] = consolidateNpcs[key].map(npc => { npc.selected = false; return npc; });
        }

        setConsolidations(consolidateNpcs);
        toastInfo(`Consolidation loaded for zoneID [${selectedZoneId}]`);
      } catch (error: any) {
        toastError(`Consolidation failed for zoneID [${selectedZoneId}]`);
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }

      endLoading();
    }
    // call the function
    fetchData();
    return () => abortController.abort();
  }, [token, state.selectedZone.id]);

  const selectZone = (event: any) => {
    var selectedZone = state.zoneData.find(zone => zone.id === Number(event.target.value))
    setSelectedZone(selectedZone);
  };

  const onChanged = (npcType: string, consolidationNpcTypes: ConsolidationNpcType[]) => {
    state.consolidationData[npcType] = consolidationNpcTypes;
    var newData: ConsolidationNpcTypes = {};
    Object.keys(state.consolidationData).forEach(key => {
      if(key === npcType) {
        newData[key] = consolidationNpcTypes;
      }

      return newData[key] = state.consolidationData[key];
    });

    setConsolidations(newData);
  }

  const itemList = Object.keys(state.consolidationData).map(key => (<ConsolidateNpcTypeTable key={key} npcType={key} consolidationNpcTypes={state.consolidationData[key]} onToggle={toggle} onToggleAll={toggleAll} />));

  return (
    <>
      <div className="container is-fluid">
        <div className="notification">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className={`select ${state.zoneData.length === 1 && 'is-loading'}`}>
                  <select value={state.selectedZone.id} onChange={selectZone}>
                    {state.zoneData.map((zone: Zone, index: number) => <option key={index} value={zone.id}>{zoneText(zone)}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="container mt-4">
          {itemList}
        </div>
      </div>
    </>
  );
}

function Consolidation() {
  return (
    <ConsolidationsProvider>
      <ConsolidationList />
    </ConsolidationsProvider>
  );
}

export default Consolidation;

// https://cssloaders.github.io/
