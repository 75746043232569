// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";
import { LatLng, latLng } from "leaflet";
import { ZoneMap, ZonePolygons } from "../../../domain/map";
import { Zone, ZoneSpawnEntry, isEnabled } from "../../../domain/zone";
import { produce } from "immer";

type ZoneSpawnEntryFilter = {
  [K in keyof ZoneSpawnEntry]?:  ZoneSpawnEntry[K][]
}

export type ZoneSpawnEntryFocusFilter = {
  [K in keyof ZoneSpawnEntry]?:  ZoneSpawnEntry[K]
}

export type SpawnGroup = {
  id: number,
  name: string,
}

export const DefaultZonePolygons: ZonePolygons = {
  polygons: [],
  converters: {
    mapCoordinatesToEQLoc: (lat, long) => { return {x:  0, y: 0} },
    eqLocToMapCoordinates: (x, y) => latLng(0, 0)
  },
  map: {
    polygons: [],
    bounds: [[0,0],[256,256]],
    center: [123,123]
  }
}

export const getZoneMapPolygons = async (zone: Zone, token: string|null): Promise<ZonePolygons> => {
  if (zone.id <= 0 ) {
    return DefaultZonePolygons
  }

  try {
    const response = await fetch(`/zones/${zone.shortName}/map`, {
      headers: {
        withCredentials: "true",
        crossorigin: "true",
        mode: 'no-cors',
        credentials: "include", // include, *same-origin, omit
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authentication: 'Bearer '+ token
      }
    });

    const mapResult: ZoneMap = await response.json() as ZoneMap;
    return {
      polygons: mapResult.polygons.map(polygon => {
        return polygon.lines.map(line => {
          return {
            coordinates: [[line.p1.x, line.p1.y], [line.p2.x, line.p2.y]],
            color: polygon.hexRGB
          }
        })
      }),
      converters: {
        mapCoordinatesToEQLoc: (lat: number, lon: number) => {
          return {x: lon*-1, y: lat};
        },
        eqLocToMapCoordinates: (y: number, x: number) => {
          return latLng(y, x*-1);
        }
      },
      map: {
        polygons: mapResult.polygons,
        bounds: mapResult.bounds,
        center: mapResult.center
      }
    }

  } catch {
    return DefaultZonePolygons;
  }
};

const filteredSpawns = (draft: State): ZoneSpawnEntry[] => {
  if(draft.filter === 2) {
    return draft.zoneSpawnEntryData.filter(x => isEnabled(x) && x.z >= draft.minZ && x.z <= draft.maxZ)
  } else if(draft.filter === 3) {
    return draft.zoneSpawnEntryData.filter(x => !isEnabled(x) && x.z >= draft.minZ && x.z <= draft.maxZ)
  } else {
    return draft.zoneSpawnEntryData.filter(x => x.z >= draft.minZ && x.z <= draft.maxZ)
  }
}

const filteredPolygons = (draft: State): ZonePolygons => {
  return produce<ZonePolygons>(draft.zonePolygons, _ => {
    _.polygons = _.map.polygons.map(polygon => {
      return polygon.lines.filter(line => line.p1.z >= draft.minZ && line.p2.z >= draft.minZ && line.p1.z <= draft.maxZ && line.p2.z <= draft.maxZ)
                          .map(line => {
                                  return {
                                    coordinates: [[line.p1.x, line.p1.y], [line.p2.x, line.p2.y]],
                                    color: polygon.hexRGB
                                  }
      })
    })
  })
}

enum Types {
  SetZones = 'SET_ZONES',
  SetSelectedZone = 'SET_SELECTED_ZONE',
  SetZoneSpawnEntryData = 'SET_ZONE_SPAWN_ENTRIES',
  SetFocusFilter = 'SET_FOCUS_FILTER',
  SetPosition = 'SET_POSITION',
  SetZonePolygons = 'SET_ZONE_POLYGONS',
  SetFilter = 'SET_FILTER',
  SetShowGrid = 'SET_SHOW_GRID',
  SetShowNpcGrid = 'SET_SHOW_NPC_GRID',
  ToggleEnabledMarker = 'TOGGLE_ENABLED_MARKER',
  ResetMarker = 'RESET_MARKER',
  SetZoom = 'SET_ZOOM',
  SetMinZ = 'SET_MIN_Z',
  SetMaxZ = 'SET_MAX_Z',
}

type State = {
  zoneData: Zone[];
  zoneSpawnEntryData: ZoneSpawnEntry[];
  filteredSpawns: ZoneSpawnEntry[];
  spawnGroups: SpawnGroup[];
  selectedZone: Zone;
  focusFilter: ZoneSpawnEntryFocusFilter;
  clickedPosition: LatLng|null;
  zonePolygons: ZonePolygons;
  filter: number;
  showgrid: boolean;
  shownpcgrid: boolean;
  markerEnabled: boolean;
  zoom: number;
  minZ: number;
  maxZ: number;
}

export const dummyZone: Zone = { id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1 };
const emptyZones: Zone[] = [dummyZone];

// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm

const reducerActions = {
  setZones: (zones: Zone[]) => { return { type: Types.SetZones as typeof Types.SetZones, payload: zones } },
  setSelectedZone: (selectedZone: Zone | undefined) => { return { type: Types.SetSelectedZone as typeof Types.SetSelectedZone, payload: selectedZone } },
  setZoneSpawnEntries: (zoneSpawnEntries: ZoneSpawnEntry[]) => { return { type: Types.SetZoneSpawnEntryData as typeof Types.SetZoneSpawnEntryData, payload: zoneSpawnEntries } },
  setFocusFilter: (focusFilter: ZoneSpawnEntryFocusFilter) => { return { type: Types.SetFocusFilter as typeof Types.SetFocusFilter, payload: focusFilter } },
  setPosition: (position: LatLng) => { return { type: Types.SetPosition as typeof Types.SetPosition, payload: position } },
  setZonePolygons: (zonePolygons: ZonePolygons) => { return { type: Types.SetZonePolygons as typeof Types.SetZonePolygons, payload: zonePolygons } },
  setFilter: (filter: number) => { return { type: Types.SetFilter as typeof Types.SetFilter, payload: filter } },
  setShowGrid: (show: boolean) => { return { type: Types.SetShowGrid as typeof Types.SetShowGrid, payload: show } },
  setShowNpcGrid: (show: boolean) => { return { type: Types.SetShowNpcGrid as typeof Types.SetShowNpcGrid, payload: show } },
  toggleEnabledMarker: () => { return { type: Types.ToggleEnabledMarker as typeof Types.ToggleEnabledMarker } },
  resetMarker: () => { return { type: Types.ResetMarker as typeof Types.ResetMarker } },
  setZoom: (zoom: number) => { return { type: Types.SetZoom as typeof Types.SetZoom, payload: zoom } },
  setMinZ: (minZ: number) => { return { type: Types.SetMinZ as typeof Types.SetMinZ, payload: minZ } },
  setMaxZ: (maxZ: number) => { return { type: Types.SetMaxZ as typeof Types.SetMaxZ, payload: maxZ } },
}
type Actions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const reducer = (draft: State, action: Actions) => {
  switch (action.type) {
    case Types.SetZones:
      draft.zoneData = action.payload;
      return draft;
    case Types.SetSelectedZone:
      draft.selectedZone = action.payload ?? dummyZone;
      return draft;
    case Types.SetZoneSpawnEntryData:
      draft.zoneSpawnEntryData = action.payload ?? [];
      const map = new Map(action.payload.map(x => [x.spawnGroupId, {id: x.spawnGroupId, name: x.spawnGroupName}]));
      draft.spawnGroups = [...map.values()];
      draft.filteredSpawns = filteredSpawns(draft);
      return draft;
    case Types.SetFocusFilter:
      draft.focusFilter = action.payload ?? {};
      return draft;
    case Types.SetPosition:
      draft.clickedPosition = action.payload;
      draft.markerEnabled = false;
      return draft;
    case Types.SetZonePolygons:
      draft.zonePolygons = action.payload;
      draft.minZ = action.payload.map.bounds[0][2] || 0
      draft.maxZ = action.payload.map.bounds[1][2] || 100
      return draft;
    case Types.SetFilter:
      draft.filter = action.payload;
      draft.filteredSpawns = filteredSpawns(draft);
      return draft;
    case Types.SetShowGrid:
      draft.showgrid = action.payload;
      return draft;
    case Types.SetShowNpcGrid:
      draft.shownpcgrid = action.payload;
      return draft;
    case Types.ToggleEnabledMarker:
      draft.markerEnabled = !draft.markerEnabled;
      return draft;
    case Types.ResetMarker:
      draft.clickedPosition = null;
      return draft;
    case Types.SetZoom:
      draft.zoom = action.payload;
      return draft;
    case Types.SetMinZ:
      draft.minZ = action.payload;
      draft.filteredSpawns = filteredSpawns(draft);
      draft.zonePolygons = filteredPolygons(draft);
      return draft;
    case Types.SetMaxZ:
      draft.maxZ = action.payload;
      draft.filteredSpawns = filteredSpawns(draft);
      draft.zonePolygons = filteredPolygons(draft);
      return draft;
  }

  return assertUnreachable(action);
};


// https://prateeksurana.me/blog/simplify-immutable-data-structures-in-usereducer-with-immer/
const dispacthActions = dispatcherWrapper<Actions, typeof reducerActions>(reducerActions);
const ZoneContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<State> }>(null!);

export const ZoneProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<State, Actions>(
    reducer,
    {
      zoneData: emptyZones,
      selectedZone: dummyZone,
      zoneSpawnEntryData: [],
      filteredSpawns: [],
      spawnGroups: [],
      focusFilter: {},
      clickedPosition: null,
      zonePolygons: DefaultZonePolygons,
      filter: 1,
      showgrid: true,
      shownpcgrid: true,
      markerEnabled: false,
      zoom: -2,
      minZ: 0,
      maxZ: 0
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <ZoneContext.Provider value={value}>{props.children}</ZoneContext.Provider>;
};


export const useZoneReducer = () => {
  const currentContext = useContext(ZoneContext);

  if (!currentContext) {
    throw new Error(
      "useZoneReducer has to be used within <ZoneContext.Provider>"
    );
  }

  return currentContext;
};
