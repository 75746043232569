// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";

export type NpcType = {
  id: number,
  name: string,
  factionId: number,
  genderId: number,
  lootTableId: number,
  raceId: number,
  bodyType: number,
  selected?: boolean,
}

export type Zone = {
  id: number,
  shortName: string,
  longName: string,
  zoneNumber: number
}

enum Types {
  SetNpcs = 'SET_NPCS',
  SetZones = 'SET_ZONES',
  SetSelectedZone = 'SET_SELECTED_ZONE',
}

type State = {
  npcs: NpcType[];
  zoneData: Zone[];
  selectedZone: Zone;
}

const dummyZone: Zone = { id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1 };
const emptyZones: Zone[] = [dummyZone];

// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm

const reducerActions = {
  setNpcs: (npcs: NpcType[]) => { return { type: Types.SetNpcs  as typeof Types.SetNpcs, payload: npcs } },
  setZones: (zones: Zone[]) => { return { type: Types.SetZones  as typeof Types.SetZones, payload: zones } },
  setSelectedZone: (selectedZone: Zone | undefined) => { return { type: Types.SetSelectedZone  as typeof Types.SetSelectedZone, payload: selectedZone } },
}
type Actions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const reducer = (draft: State, action: Actions) => {
  switch (action.type) {
    case Types.SetNpcs:
      draft.npcs = action.payload;
      return draft;
    case Types.SetZones:
      draft.zoneData = action.payload;
      return draft;
    case Types.SetSelectedZone:
      draft.selectedZone = action.payload ?? dummyZone;
      return draft;
  }

  return assertUnreachable(action);
};


// https://prateeksurana.me/blog/simplify-immutable-data-structures-in-usereducer-with-immer/
const dispacthActions = dispatcherWrapper<Actions, typeof reducerActions>(reducerActions);
const NpcsContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<State> }>(null!);

export const NpcsProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<State, Actions>(
    reducer,
    {
      npcs: [],
      zoneData: emptyZones,
      selectedZone: dummyZone
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <NpcsContext.Provider value={value}>{props.children}</NpcsContext.Provider>;
};


export const useNpcsReducer = () => {
  const currentContext = useContext(NpcsContext);

  if (!currentContext) {
    throw new Error(
      "useNpcsReducer has to be used within <NpcsContext.Provider>"
    );
  }

  return currentContext;
};
