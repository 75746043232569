import { CircleMarker, LayerGroup, MapContainer, Marker, Polyline, TileLayer, Tooltip, useMapEvents } from 'react-leaflet'
import { CRS, LatLng, LatLngTuple } from "leaflet";
import { Polygon, ZonePolygon, ZonePolygons } from "../../domain/map";
import { Zone, ZoneSpawnEntry } from "../../domain/zone";
import { GridLines } from "./GridLine";
import { ChangeMapView } from "./ChangeMapView";
import { SpawnPoint } from "./SpawnPoint";
import { Fragment } from 'react';

function MapClicked(props: {setPosition?: (position: LatLng) => void, setZoom?: (zoom: number) => void}) {
  const map = useMapEvents({
    click(e) {
      if(props.setPosition) {
        props.setPosition(e.latlng)
      }
    },
    zoom(event) {
      // console.info(event.target.getZoom())
      // console.info(event.target.getBounds())
      if(props.setZoom) {
        props.setZoom(event.target.getZoom())
      }
    }
  })

  return null
}

type ZoneMapProps = {
  zonePolygons: ZonePolygons,
  debugPolygons?: {key: number, polygon: Polygon}[],
  zone: Zone,
  zoom: number;
  spawns: ZoneSpawnEntry[],
  showgrid?: boolean;
  shownpcgrid?: boolean;
  clickedPosition: LatLng|null;
  setPosition?: (position: LatLng) =>void,
  setZoom?: (zoom: number) => void,
  isFocused?: (spawnpoint: ZoneSpawnEntry) => boolean,
};

export const ZoneMap = ({zonePolygons, debugPolygons, zone, zoom, spawns, showgrid, shownpcgrid, clickedPosition, setPosition, setZoom, isFocused}: ZoneMapProps) => {
  const renderPolyLine = (tableData: ZonePolygon) => {
    return tableData.map((item, index) => {
      return (
        <Polyline key={index} pathOptions={{ color: item.color }} positions={item.coordinates} weight={1} />
      );
    })
  }

  const renderPolyLines = (tableData: ZonePolygons) => {
    return tableData.polygons.map((item, index) => {
      return (
        <LayerGroup key={index}>
          {renderPolyLine(item)}
        </LayerGroup>
      );
    })
  }

  const testpoints:LatLngTuple[] = []
  // const testpoints:LatLngTuple[] = [[-352,154], [-4,-186], [-57,-2], [-332,-543], [-277,-555], [-293,-528], [774,-607], [815,-592], [139,-78], [-84,0], [7,214], [586,-34], [568,-30], [310,-12], [387,-9], [-407,140], [507,5], [347,-89], [-292,-18], [-67,-522], [222,-52], [-353,-19], [516,16], [855,-531], [977,-633], [995,-410], [1064,-493], [1035,-614], [1200,-673], [1205,-543], [975,-854], [1244,-543], [-47,139], [363,443], [-199,251], [405,444], [928,-609], [1075,-624], [1313,-543], [-373,-559], [-106,506], [960,-838], [1360,-559], [990,-1080], [-229,210], [-150,518], [510,207], [30,173], [391,-74], [-285,-68], [-142,-28], [-161,-294], [-5,-212], [-15,-202], [-75,0], [-113,-285], [-104,-54], [79,-64], [-85,23], [-84,-21], [-12,20], [-292,-18], [-216,-317], [-182,-406], [-183,-381], [-48,-375], [-98,49], [-106,-551], [340,-363], [684,-535], [815,-428], [-151,36], [-85,-285], [81,-36], [485,-14], [582,-24], [9,152], [815,-449], [-122,-79], [-154,-280], [-329,-272], [-97,-299], [-361,-535], [855,-507], [890,-593], [877,-579], [934,-534], [1065,-564], [1234,-648], [1233,-489]]
  const renderDebugSpawnPoints = () => {
    const color = 'hsla(240, 100%, 50%, 1)'
    const testcircles = testpoints.map((item, index) => {
      return (
        <CircleMarker key={index} center={zonePolygons.converters.eqLocToMapCoordinates(item[1], item[0])} radius={3} weight={6} stroke={true} color={color} fillColor={color} />
      );
    });
    return (
      <LayerGroup key='DebugSpawnPoints'>
        {testcircles}
      </LayerGroup>
    );
  }

  const renderPositionClicked = () => {
    if (clickedPosition != null) {
      const xy = zonePolygons.converters.mapCoordinatesToEQLoc(clickedPosition.lat, clickedPosition.lng);
      return <Marker position={clickedPosition}>
        <Tooltip>(x,y) {xy.x}, {xy.y}</Tooltip>
      </Marker>
    }

    return null
  }

  const renderSpawnPoints = (spawnPoints: ZoneSpawnEntry[]) => {
    return spawnPoints.map(spawnPoint => <SpawnPoint key={spawnPoint.id} spawnpoint={spawnPoint} isFocused={isFocused} zonePolygons={zonePolygons} showgrid={shownpcgrid} />)
  }

  const renderDebugPolyLine = () => {
    if(!debugPolygons) {
      return <Fragment />
    }

    return debugPolygons.map(item => {
      return <LayerGroup key={item.key}>
        {
          item.polygon.lines.map((line, index) => {
            var coordinates = [zonePolygons.converters.eqLocToMapCoordinates(line.p1.y, line.p1.x), zonePolygons.converters.eqLocToMapCoordinates(line.p2.y, line.p2.x)];
            return <Polyline key={`${item.key}-${index}`} pathOptions={{ color: item.polygon.hexRGB }} positions={coordinates} weight={1} dashArray='2, 2' dashOffset='0' />
          })
        }
        </LayerGroup>
    });
  }

  return (
    <MapContainer center={zonePolygons.map.center}
                      zoom={-2}
                      crs={CRS.Simple}
                      minZoom={-6}
                      maxZoom={6}
                      bounds={zonePolygons.map.bounds}>
          <ChangeMapView center={zonePolygons.map.center} zoom={-2} />
          <TileLayer
            url=""
            attribution={zone.id < 1 ? '' : zone.longName}
            minZoom={-6}
            maxZoom={6}
          />
          {renderPolyLines(zonePolygons)}
          {renderSpawnPoints(spawns)}
          {renderPositionClicked()}
          {renderDebugSpawnPoints()}
          {renderDebugPolyLine()}
          <GridLines zoom={zoom} showgrid={showgrid} zone={zone}/>
          <MapClicked setPosition={setPosition} setZoom={setZoom}/>
          {/* <MinimapControl position="topright" polygons={zonePolygons} zoom={-4} bounds={zonePolygons.map.bounds}/> */}
    </MapContainer>
  )
}
